import React from "react";
import { NavbarServices } from "../components/NavbarServices";
import { Footer } from "../components/Footer";
import { useTranslation } from "react-i18next";

function Careers() {
  const { t } = useTranslation();
  return (
    <div>
      <NavbarServices />
      <div className="pb-24">
        <section className="px-4 sm:px-8 pb-24">
          <div className="mx-auto max-w-[75rem] space-y-8">
            <div className="py-6 border-b-[0.5px] border-[#A9A9A9]">
              <div className="container mx-auto">
                <h2 className="pt-6 text-2xl sm:text-3xl font-bold">
                  Looking for a job?
                </h2>
                <h2 className="pt-6 text-xl tracki font-normal">
                  Upload your CV!
                </h2>
              </div>
            </div>

            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    aria-hidden="true"
                    className="w-10 h-10 mb-3 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    PDF, DOCX, TXT or XLSX (MAX. 800x400px)
                  </p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Careers;
