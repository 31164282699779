import React from "react";
import { Navigation, A11y, Autoplay } from "swiper";
import partner1 from "../assets/icons/METALCRAFT_LOGO.png";
import partner2 from "../assets/icons/ORSAM-ENERGIES_ORSAM-ENERGIES-cyan.png";
import partner3 from "../assets/icons/anglogold-ashanti-logo.png";
import partner4 from "../assets/icons/download (10).png";
import partner5 from "../assets/icons/download (11).png";
import partner6 from "../assets/icons/download (8).png";
import partner7 from "../assets/icons/download (9).png";
import partner8 from "../assets/icons/ibistek_logo.png";
import partner9 from "../assets/icons/rs=w_1280.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { useTranslation } from "react-i18next";

export default function Partners() {
  const { t } = useTranslation();
  return (
    <div>
      <section className="px-4 sm:px-8">
        <div className="mx-auto max-w-[75rem] space-y-8">
          <div className="py-6 border-b-[0.2px] border-b-[#A9A9A9]">
            <div className="container mx-auto">
              <div className="flex flex-col lg:flex-row items-center justify-between">
                <h2 className="text-center text-xl tracki font-normal">
                {t("Gaspark_is_trusted_by")}
                </h2>
              </div>
            </div>
          </div>
          <section className=" py-6 md:pt-18 md:pb-24">
            <div className="mx-auto max-w-6xl space-y-8">
              <Swiper
                // install Swiper modules
                modules={[Navigation, A11y, Autoplay]}
                slidesPerView={9}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                  },
                  375: {
                    slidesPerView: 3,
                  },
                  575: {
                    slidesPerView: 4,
                  },
                  767: {
                    slidesPerView: 5,
                  },
                  991: {
                    slidesPerView: 6,
                  },
                  1024: {
                    slidesPerView: 8,
                  },
                }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
              >
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center ">
                      <img
                        className=" border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner1}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center ">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner2}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner3}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner4}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center ">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner5}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner6}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner7}
                        loading="lazy"
                        alt=""
                      />{" "}
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner8}
                        loading="lazy"
                        alt=""
                      />{" "}
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner9}
                        loading="lazy"
                        alt=""
                      />{" "}
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center ">
                      <img
                        className=" border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner1}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center ">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner2}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner3}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner4}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center ">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner5}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner6}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner7}
                        loading="lazy"
                        alt=""
                      />{" "}
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner8}
                        loading="lazy"
                        alt=""
                      />{" "}
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="">
                    <div className="inline-flex item-center">
                      <img
                        className="border-[0.1px] border-[#1A428A] border-opacity-10 rounded-full p-6"
                        src={partner9}
                        loading="lazy"
                        alt=""
                      />{" "}
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
