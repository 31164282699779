import React from "react";
import Ransford from "../../assets/images/Ransford.webp";
import RansfordStoryImg from "../../assets/images/ransfordstory.webp";
import { useTranslation } from "react-i18next";

function RansfordStory() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  return (
    <div>
      <>
        <div
          className="cursor-pointer"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-once="true"
          onClick={() => setShowModal(true)}
        >
          <div className="group relative pb-[20rem] md:pb-56 mb-4  shadow lg:pb-64 overflow-hidden bg-cover bg-no-repeat">
            <img
              className="absolute object-cover w-full  transition duration-500 ease-in-out hover:scale-110"
              src={Ransford}
              alt="Person"
            />
            {/* <div
            className="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 opacity-0 group-hover:h-full group-hover:opacity-100 duration-500">
            <h1 className="text-2xl text-white">Ransford Kobina </h1>
            <a className="mt-5 px-8 py-3 rounded-full bg-amber-400 hover:bg-amber-600 duration-300" href="#">View More</a>
        </div> */}
          </div>
          <div className="flex justify-between sm:text-left">
            <div>
              <p className="text-lg font-bold">Ransford Kobina Arthur</p>
              <p className="mb-4 md:mb-0 text-xs text-gray-800">
              {t("Chief_Executive_Officer")}
              </p>
            </div>
            {/* <FaLinkedin className="text-[#1A428A] text-xl" /> */}
          </div>
        </div>
        {showModal ? (
          <>
            <div
              className="justify my-4 lg:my-10 center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <div
                className="relative w-auto my-6 mx-auto max-w-7xl"
                onClick={() => setShowModal(false)}
              >
                {/*content*/}

                <div className="bg-white border border-gray-200 shadow">
                  <a href="#">
                    <img className="" src={RansfordStoryImg} alt="" />
                  </a>
                  <div className="p-5 lg:p-10">
                    <a href="#">
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                        Ransford Kobina Arthur
                      </h5>
                    </a>
                    <p className="mb-8">{t("Chief_Executive_Officer")}</p>
                    <p className="mb-3 font-normal text-gray-700">
                    {t("Ransford_Bio")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    </div>
  );
}

export default RansfordStory;
