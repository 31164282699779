import React, { useEffect } from "react";
import { NavbarHome } from "../components/NavbarHome";
import Engineer from "../assets/icons/cranewhite.svg";
import { Footer } from "../components/Footer";
import Bullet from "../assets/icons/bullet.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import CallModal3 from "../components/ContactModal/CallModal3";
import { useTranslation } from "react-i18next";

function EngineeringDesign() {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
      AOS.refresh();
    });
  }, []);
  return (
    <div>
      <div className="bggEngineer">
        <NavbarHome />
        <div>
          <section className="text-gray-100">
            <div className="container mx-auto flex flex-col items-left px-4 py-16 text-left md:pb-36 md:px-6  lg:max-w-7xl">
              <img
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
                src={Engineer}
                className="w-14  pb-16 "
                alt=""
              />
              <h1
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-once="true"
                className="text-3xl font-bold  md:text-4xl lg:text-5xl"
              >
                {t("Engineering_Design")}
              </h1>
            </div>
          </section>
        </div>
      </div>

      <section className="px-4 sm:px-8">
        <div className="mx-auto max-w-7xl space-y-8">
          <h2
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="true"
            className="text-left text-xl lg:text-3xl lg:w-[42rem] font-bold my-6 md:m-6"
          >
            {t("Pioneering")}
            <br />
            {t("and_refine_for_optimal_solutions.")}
          </h2>

          <div className="max-w-2xl mx-auto text-gray-800">
            <ul className="space-y-12">
              <li className="flex items-start space-x-3">
                <div className="flex-1 space-y-2 mt-8 mb-4">
                  <div>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1500"
                      data-aos-once="true"
                    >
                      {t("We_lead_the_way")}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="max-w-2xl mx-auto">
            <CallModal3 />
          </div>
          <section className="text-gray-800">
            <div className="container px-4 pt-6 pb-24 mx-auto space-y-8 lg:max-w-3xl">
              <div className="space-y-8">
                <div
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  data-aos-once="true"
                >
                  <ul className="space-y-4">
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("Fabrication")}</h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("Welding")}</h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t("Surface_Treatment")}
                        </h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("Rubber_Lining")}</h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t("Conveyor_Belt_Installation")}
                        </h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("Erection")}</h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("Maintenance")}</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default EngineeringDesign;
