import React, { useState } from "react";
import image1 from "../assets/images/contractor.webp";
import image2 from "../assets/images/hero1.webp";
import image3 from "../assets/images/hero2.webp";
import image4 from "../assets/images/hero3.webp";
import image5 from "../assets/images/hero5.webp";

const images = [image1, image2, image3, image4, image5];

const RandomImage: React.FC = () => {
  const [imageUrl, setImageUrl] = useState("");

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    const randomImage = images[randomIndex];
    setImageUrl(randomImage);
  };

  // Call getRandomImage when the component mounts
  useState(getRandomImage);

  return (
    <div>
      {/* <img src={imageUrl} alt="Random Image" /> */}
      <img
        data-aos="fade-left"
        data-aos-duration="2000"
        data-aos-once="true"
        className="w-[70%] lg:w-[56%] mx-auto lg:ml-auto lg:mx-0"
        src={imageUrl}
        alt=""
      />
      {/* <button onClick={getRandomImage}>Reload</button> */}
    </div>
  );
};

export default RandomImage;
