import React, { useEffect } from "react";
import { NavbarServices } from "../components/NavbarServices";
import { Footer } from "../components/Footer";
import ContStaff from "../assets/icons/helmet.svg";
import Engineer from "../assets/icons/crane.svg";
import Project from "../assets/icons/settings.svg";
import Procurement from "../assets/icons/procre.svg";
import cardbg from "../assets/images/Group 56.webp";
import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import CallModal2 from "../components/ContactModal/CallModal2";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
      AOS.refresh();
    });
  }, []);
  return (
    <div>
      <NavbarServices />
      <div>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-5">
            <div className="grid gap-1 md:grid-cols-2 md:col-span-2 lg:col-span-3">
              <Link
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
                className="group [transform:translateZ(0)]"
                to="/contract-staffing"
              >
                <div className="relative p-px overflow-hidden  hover:bg-opacity-0 rounded mr-1 mb-1">
                  <div className="contractbg">
                    <div className="bg-[#EEEEEE] bg-opacity-0 lg:bg-opacity-100 group-hover:bg-opacity-0 group-hover:transition group-hover:ease-in-out group-hover:duration-500">
                      <div className="relative p-4 md:p-8 rounded-sm">
                        <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
                          <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full lg:mb-0">
                            <img
                              className=" translate-y-0 duration-500 group-hover:-translate-y-24 group-hover:duration-500"
                              src={ContStaff}
                              alt=""
                            />
                          </div>
                        </div>
                        <p className="mt-16 mb-8 group-hover:text-white text-base text-white lg:text-[#1A428A]">
                        {t("CONTRACT_STAFFING")}
                        </p>
                        <RiArrowRightSLine className="inline-flex rounded-full border-2 border-[#1A428A]  items-center text-3xl font-semibold group-hover:text-white text-white lg:text-[#1A428A] bg-[#1A428A] lg:bg-transparent group-hover:bg-[#1A428A]" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-once="true"
                className="group [transform:translateZ(0)]"
                to="/engineering-design"
              >
                <div className="relative p-px overflow-hidden  hover:bg-opacity-0 rounded mr-1 mb-1">
                  <div className="engineerbg">
                    <div className="bg-[#EEEEEE] bg-opacity-0 lg:bg-opacity-100 group-hover:bg-opacity-0 group-hover:transition group-hover:ease-in-out group-hover:duration-500">
                      <div className="relative p-4 md:p-8 rounded-sm">
                        <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
                          <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full lg:mb-0">
                            <img
                              className=" translate-y-0 duration-500 group-hover:-translate-y-24 group-hover:duration-500"
                              src={Engineer}
                              alt=""
                            />
                          </div>
                        </div>
                        <p className="mt-16 mb-8 group-hover:text-white text-base text-white lg:text-[#1A428A]">
                        {t("ENGINEERING_DESIGN")}
                        </p>
                        <RiArrowRightSLine className="inline-flex rounded-full border-2 border-[#1A428A]  items-center text-3xl font-semibold group-hover:text-white text-white lg:text-[#1A428A] bg-[#1A428A] lg:bg-transparent group-hover:bg-[#1A428A]" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="true"
                className="group [transform:translateZ(0)]"
                to="/project-management"
              >
                <div className="relative p-px overflow-hidden  hover:bg-opacity-0 rounded mr-1 mb-1">
                  <div className="projectbg">
                    <div className="bg-[#EEEEEE] bg-opacity-0 lg:bg-opacity-100 group-hover:bg-opacity-0 group-hover:transition group-hover:ease-in-out group-hover:duration-500">
                      <div className="relative p-4 md:p-8 rounded-sm">
                        <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
                          <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full lg:mb-0">
                            <img
                              className=" translate-y-0 duration-500 group-hover:-translate-y-24 group-hover:duration-500"
                              src={Project}
                              alt=""
                            />
                          </div>
                        </div>
                        <p className="mt-16 mb-8 group-hover:text-white text-base text-white lg:text-[#1A428A]">
                        {t("PROJECT_MANAGEMENT")}
                        </p>
                        <RiArrowRightSLine className="inline-flex rounded-full border-2 border-[#1A428A]  items-center text-3xl font-semibold group-hover:text-white text-white lg:text-[#1A428A] bg-[#1A428A] lg:bg-transparent group-hover:bg-[#1A428A]" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-once="true"
                className="group [transform:translateZ(0)]"
                to="/procurement"
              >
                <div className="relative p-px overflow-hidden  hover:bg-opacity-0 rounded mr-1 mb-1">
                  <div className="procurebg">
                    <div className="bg-[#EEEEEE] bg-opacity-0 lg:bg-opacity-100 group-hover:bg-opacity-0 group-hover:transition group-hover:ease-in-out group-hover:duration-500">
                      <div className="relative p-4 md:p-8 rounded-sm">
                        <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
                          <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full lg:mb-0">
                            <img
                              className="h-full translate-y-0 duration-500 group-hover:-translate-y-24 group-hover:duration-500"
                              src={Procurement}
                              alt=""
                            />
                          </div>
                        </div>
                        <p className="mt-16 mb-8 group-hover:text-white text-base text-white lg:text-[#1A428A]">
                        {t("PROCUREMENT")}
                        </p>
                        <RiArrowRightSLine className="inline-flex rounded-full border-2 border-[#1A428A]  items-center text-3xl font-semibold group-hover:text-white text-white lg:text-[#1A428A] bg-[#1A428A] lg:bg-transparent group-hover:bg-[#1A428A]" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div
             
              className="relative md:col-span-1 mb-1 lg:col-span-1"
            >
              <div className="relative mx-auto flex h-full w-full items-center justify-center overflow-hidden  bg-gray-100 shadow-lg sm:w-96">
                <img
                  src={cardbg}
                  loading="lazy"
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover object-center"
                />

                <div className="relative flex flex-col items-start justify-center p-8 ">
                  <h1 className="mb-36 text-left text-2xl font-bold text-white md:text-3xl lg:text-3xl">
                  {t("At_Gaspark")}
                  </h1>
                  <CallModal2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Services;
