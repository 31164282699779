import { NavbarServices } from "../components/NavbarServices";
import { Footer } from "../components/Footer";
import Belmet1 from "../assets/belmet/belmet1.webp";
import Belmet2 from "../assets/belmet/belmet2.webp";
import Belmet3 from "../assets/belmet/belmet3.webp";
import Belmet4 from "../assets/belmet/belmet4.webp";
import Belmet5 from "../assets/belmet/belmet5.webp";
import Belmet6 from "../assets/belmet/belmet6.webp";
import Belmet7 from "../assets/belmet/belmet7.webp";
import Belmet8 from "../assets/belmet/belmet8.webp";
import Belmet9 from "../assets/belmet/belmet9.webp";
import Belmet10 from "../assets/belmet/belmet10.webp";
import Belmet11 from "../assets/belmet/belmet11.webp";
import Belmet12 from "../assets/belmet/belmet12.webp";
import Belmet13 from "../assets/belmet/belmet13.webp";
import Belmet14 from "../assets/belmet/belmet14.webp";
import Belmet15 from "../assets/belmet/belmet15.webp";
import Belmet16 from "../assets/belmet/belmet16.webp";
import Hit1 from "../assets/hit/hit1.webp";
import Hit2 from "../assets/hit/hit2.webp";
import Hit3 from "../assets/hit/hit3.webp";
import Hit4 from "../assets/hit/hit4.webp";
import Hit5 from "../assets/hit/hit5.webp";
import Hit6 from "../assets/hit/hit6.webp";
import Hit7 from "../assets/hit/hit7.webp";
import Hit8 from "../assets/hit/hit8.webp";
import Hit9 from "../assets/hit/hit9.webp";
import Hit10 from "../assets/hit/hit10.webp";
import Hit11 from "../assets/hit/hit11.webp";
import Hit12 from "../assets/hit/hit12.webp";
import Hit13 from "../assets/hit/hit13.webp";
import Hit14 from "../assets/hit/hit14.webp";
import Hit15 from "../assets/hit/hit15.webp";
import Hit16 from "../assets/hit/hit16.webp";
import Hit17 from "../assets/hit/hit17.webp";
import Hit18 from "../assets/hit/hit18.webp";
import Hit19 from "../assets/hit/hit19.webp";
import Hit20 from "../assets/hit/hit20.webp";
import Hit21 from "../assets/hit/hit21.webp";
import Hit22 from "../assets/hit/hit22.webp";
import Hit23 from "../assets/hit/hit23.webp";
import Hit24 from "../assets/hit/hit24.webp";
import Hit25 from "../assets/hit/hit25.webp";
import Hit26 from "../assets/hit/hit26.webp";
import Hit27 from "../assets/hit/hit27.webp";
import Hit28 from "../assets/hit/hit28.webp";
import Hit29 from "../assets/hit/hit29.webp";
import Hit30 from "../assets/hit/hit30.webp";
import Hit31 from "../assets/hit/hit31.webp";
import Hit32 from "../assets/hit/hit32.webp";
import Hit33 from "../assets/hit/hit33.webp";
import Hit34 from "../assets/hit/hit34.webp";
import Hit35 from "../assets/hit/hit35.webp";
import Mauritius1 from "../assets/mauritius/mauritius1.webp";
import Mauritius2 from "../assets/mauritius/mauritius2.webp";
import Mauritius3 from "../assets/mauritius/mauritius3.webp";
import Mauritius4 from "../assets/mauritius/mauritius4.webp";
import Mauritius5 from "../assets/mauritius/mauritius5.webp";
import Mauritius6 from "../assets/mauritius/mauritius6.webp";
import Mauritius7 from "../assets/mauritius/mauritius7.webp";
import Mauritius8 from "../assets/mauritius/mauritius8.webp";
import Faso1 from "../assets/faso/faso1.webp";
import Faso2 from "../assets/faso/faso2.webp";
import Faso3 from "../assets/faso/faso3.webp";
import Faso4 from "../assets/faso/faso4.webp";
import Faso5 from "../assets/faso/faso5.webp";
import Leone1 from "../assets/Leone/leone1.webp";
import Leone2 from "../assets/Leone/leone2.webp";
import Leone3 from "../assets/Leone/leone3.webp";
import Leone4 from "../assets/Leone/leone4.webp";
import Leone5 from "../assets/Leone/leone5.webp";
import Leone6 from "../assets/Leone/leone6.webp";
import Leone7 from "../assets/Leone/leone7.webp";
import Leone8 from "../assets/Leone/leone8.webp";
import Leone9 from "../assets/Leone/leone9.webp";
import Leone10 from "../assets/Leone/leone10.webp";
import Leone11 from "../assets/Leone/leone11.webp";
import Leone12 from "../assets/Leone/leone12.webp";
import Leone13 from "../assets/Leone/leone13.webp";
import Leone14 from "../assets/Leone/leone14.webp";
import Leone15 from "../assets/Leone/leone15.webp";
import Leone16 from "../assets/Leone/leone16.webp";
import Leone17 from "../assets/Leone/leone17.webp";
import Leone18 from "../assets/Leone/leone18.webp";
import Orsam1 from "../assets/orsam/orsam1.webp";
import Orsam2 from "../assets/orsam/orsam2.webp";
import Orsam3 from "../assets/orsam/orsam3.webp";
import Orsam4 from "../assets/orsam/orsam4.webp";
import Orsam5 from "../assets/orsam/orsam5.webp";
import Orsam6 from "../assets/orsam/orsam6.webp";
import Orsam7 from "../assets/orsam/orsam7.webp";
import Orsam8 from "../assets/orsam/orsam8.webp";
import Orsam9 from "../assets/orsam/orsam9.webp";
import Orsam10 from "../assets/orsam/orsam10.webp";
import Orsam11 from "../assets/orsam/orsam11.webp";
import Crewing1 from "../assets/crewing/crewing1.webp";
import Crewing2 from "../assets/crewing/crewing2.webp";
import Crewing3 from "../assets/crewing/crewing3.webp";
import Crewing4 from "../assets/crewing/crewing4.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Partners from "../components/Partners";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function Media() {
  const { t } = useTranslation();
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  useEffect(() => {
    setTimeout(() => {
      AOS.init();
      AOS.refresh();
    });
  }, []);

  return (
    <div id="media">
      <NavbarServices />
      <div className="pb-24">
        <section className="px-4 sm:px-8 pb-24">
          <div className="mx-auto max-w-[75rem] space-y-8">
            <div className="py-6 border-b-[0.5px] border-[#A9A9A9]">
              <div className="container mx-auto">
                <h2 className="pt-6 text-2xl sm:text-3xl font-bold">
                {t("Take_a_look_at_all_our_projects")}
                </h2>
                <h2 className="pt-6 text-xl tracki font-normal">
                {t("Gaspark_is_trusted")}
                </h2>
              </div>
            </div>

            <div className="flex flex-wrap -m-2">
              <div
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
                className="relative p-2 lg:w-1/3 md:w-1/2 w-full"
              >
                <Slider {...settings}>
                  <div>
                    <img src={Belmet1} alt="" />
                  </div>
                  <div>
                    <img src={Belmet2} alt="" />
                  </div>
                  <div>
                    <img src={Belmet3} alt="" />
                  </div>
                  <div>
                    <img src={Belmet4} alt="" />
                  </div>
                  <div>
                    <img src={Belmet5} alt="" />
                  </div>
                  <div>
                    <img src={Belmet6} alt="" />
                  </div>
                  <div>
                    <img src={Belmet7} alt="" />
                  </div>
                  <div>
                    <img src={Belmet3} alt="" />
                  </div>
                  <div>
                    <img src={Belmet8} alt="" />
                  </div>
                  <div>
                    <img src={Belmet9} alt="" />
                  </div>
                  <div>
                    <img src={Belmet10} alt="" />
                  </div>
                  <div>
                    <img src={Belmet11} alt="" />
                  </div>
                  <div>
                    <img src={Belmet12} alt="" />
                  </div>
                  <div>
                    <img src={Belmet13} alt="" />
                  </div>
                  <div>
                    <img src={Belmet14} alt="" />
                  </div>
                  <div>
                    <img src={Belmet15} alt="" />
                  </div>
                  <div>
                    <img src={Belmet16} alt="" />
                  </div>
                </Slider>
                <div className="absolute inset-x-[8px] bottom-[15px] px-6 pb-[1.2rem] bg-gradient-to-t from-[#2d2d2d] to-transparent">
                  <p className="text-sm   tracking-wide text-white">Belmet</p>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-once="true"
                className="relative p-2 lg:w-1/3 md:w-1/2 w-full"
              >
                <Slider {...settings}>
                  <div>
                    <img src={Hit1} alt="" />
                  </div>
                  <div>
                    <img src={Hit2} alt="" />
                  </div>
                  <div>
                    <img src={Hit3} alt="" />
                  </div>
                  <div>
                    <img src={Hit4} alt="" />
                  </div>
                  <div>
                    <img src={Hit5} alt="" />
                  </div>
                  <div>
                    <img src={Hit6} alt="" />
                  </div>
                  <div>
                    <img src={Hit7} alt="" />
                  </div>
                  <div>
                    <img src={Hit8} alt="" />
                  </div>
                  <div>
                    <img src={Hit9} alt="" />
                  </div>
                  <div>
                    <img src={Hit10} alt="" />
                  </div>
                  <div>
                    <img src={Hit11} alt="" />
                  </div>
                  <div>
                    <img src={Hit12} alt="" />
                  </div>
                  <div>
                    <img src={Hit13} alt="" />
                  </div>
                  <div>
                    <img src={Hit14} alt="" />
                  </div>
                  <div>
                    <img src={Hit15} alt="" />
                  </div>
                  <div>
                    <img src={Hit16} alt="" />
                  </div>
                  <div>
                    <img src={Hit17} alt="" />
                  </div>
                  <div>
                    <img src={Hit18} alt="" />
                  </div>
                  <div>
                    <img src={Hit19} alt="" />
                  </div>
                  <div>
                    <img src={Hit20} alt="" />
                  </div>
                  <div>
                    <img src={Hit21} alt="" />
                  </div>
                  <div>
                    <img src={Hit22} alt="" />
                  </div>
                  <div>
                    <img src={Hit23} alt="" />
                  </div>
                  <div>
                    <img src={Hit24} alt="" />
                  </div>
                  <div>
                    <img src={Hit25} alt="" />
                  </div>
                  <div>
                    <img src={Hit26} alt="" />
                  </div>
                  <div>
                    <img src={Hit27} alt="" />
                  </div>
                  <div>
                    <img src={Hit28} alt="" />
                  </div>
                  <div>
                    <img src={Hit29} alt="" />
                  </div>
                  <div>
                    <img src={Hit30} alt="" />
                  </div>
                  <div>
                    <img src={Hit31} alt="" />
                  </div>

                  <div>
                    <img src={Hit32} alt="" />
                  </div>
                  <div>
                    <img src={Hit33} alt="" />
                  </div>

                  <div>
                    <img src={Hit34} alt="" />
                  </div>
                  <div>
                    <img src={Hit35} alt="" />
                  </div>
                </Slider>
                <div className="absolute inset-x-[8px] bottom-[15px] px-6 pb-[1.2rem] bg-gradient-to-t from-[#2d2d2d] to-transparent">
                  <p className="text-sm   tracking-wide text-white">HIT</p>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="true"
                className="relative p-2 lg:w-1/3 md:w-1/2 w-full"
              >
                <Slider {...settings}>
                  <div>
                    <img src={Mauritius1} alt="" />
                  </div>
                  <div>
                    <img src={Mauritius2} alt="" />
                  </div>
                  <div>
                    <img src={Mauritius3} alt="" />
                  </div>
                  <div>
                    <img src={Mauritius4} alt="" />
                  </div>
                  <div>
                    <img src={Mauritius5} alt="" />
                  </div>
                  <div>
                    <img src={Mauritius6} alt="" />
                  </div>
                  <div>
                    <img src={Mauritius7} alt="" />
                  </div>
                  <div>
                    <img src={Mauritius8} alt="" />
                  </div>
                </Slider>
                <div className="absolute inset-x-[8px] bottom-[15px] px-6 pb-[1.2rem] bg-gradient-to-t from-[#2d2d2d] to-transparent">
                  <p className="text-sm   tracking-wide text-white">
                    Mauritius
                  </p>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-once="true"
                className="relative p-2 lg:w-1/3 md:w-1/2 w-full"
              >
                <Slider {...settings}>
                  <div>
                    <img src={Faso1} alt="" />
                  </div>
                  <div>
                    <img src={Faso2} alt="" />
                  </div>
                  <div>
                    <img src={Faso3} alt="" />
                  </div>
                  <div>
                    <img src={Faso4} alt="" />
                  </div>
                  <div>
                    <img src={Faso5} alt="" />
                  </div>
                </Slider>
                <div className="absolute inset-x-[8px] bottom-[15px] px-6 pb-[1.2rem] bg-gradient-to-t from-[#2d2d2d] to-transparent">
                  <p className="text-sm   tracking-wide text-white">
                    Burkina Faso
                  </p>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="2500"
                data-aos-once="true"
                className="relative p-2 lg:w-1/3 md:w-1/2 w-full"
              >
                <Slider {...settings}>
                  <div>
                    <img src={Leone1} alt="" />
                  </div>
                  <div>
                    <img src={Leone2} alt="" />
                  </div>
                  <div>
                    <img src={Leone3} alt="" />
                  </div>
                  <div>
                    <img src={Leone4} alt="" />
                  </div>
                  <div>
                    <img src={Leone5} alt="" />
                  </div>
                  <div>
                    <img src={Leone6} alt="" />
                  </div>
                  <div>
                    <img src={Leone7} alt="" />
                  </div>
                  <div>
                    <img src={Leone8} alt="" />
                  </div>
                  <div>
                    <img src={Leone9} alt="" />
                  </div>
                  <div>
                    <img src={Leone10} alt="" />
                  </div>
                  <div>
                    <img src={Leone11} alt="" />
                  </div>
                  <div>
                    <img src={Leone12} alt="" />
                  </div>
                  <div>
                    <img src={Leone13} alt="" />
                  </div>
                  <div>
                    <img src={Leone14} alt="" />
                  </div>
                  <div>
                    <img src={Leone15} alt="" />
                  </div>
                  <div>
                    <img src={Leone16} alt="" />
                  </div>
                  <div>
                    <img src={Leone17} alt="" />
                  </div>
                  <div>
                    <img src={Leone18} alt="" />
                  </div>
                </Slider>
                <div className="absolute inset-x-[8px] bottom-[15px] px-6 pb-[1.2rem] bg-gradient-to-t from-[#2d2d2d] to-transparent">
                  <p className="text-sm   tracking-wide text-white">
                    Seirra Leone
                  </p>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-once="true"
                className="relative p-2 lg:w-1/3 md:w-1/2 w-full"
              >
                <Slider {...settings}>
                  <div>
                    <img src={Orsam1} alt="" />
                  </div>
                  <div>
                    <img src={Orsam2} alt="" />
                  </div>
                  <div>
                    <img src={Orsam3} alt="" />
                  </div>
                  <div>
                    <img src={Orsam4} alt="" />
                  </div>
                  <div>
                    <img src={Orsam5} alt="" />
                  </div>
                  <div>
                    <img src={Orsam6} alt="" />
                  </div>
                  <div>
                    <img src={Orsam7} alt="" />
                  </div>
                  <div>
                    <img src={Orsam8} alt="" />
                  </div>
                  <div>
                    <img src={Orsam9} alt="" />
                  </div>
                  <div>
                    <img src={Orsam10} alt="" />
                  </div>
                  <div>
                    <img src={Orsam11} alt="" />
                  </div>
                </Slider>
                <div className="absolute inset-x-[8px] bottom-[15px] px-6 pb-[1.2rem] bg-gradient-to-t from-[#2d2d2d] to-transparent">
                  <p className="text-sm   tracking-wide text-white">Orsam</p>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="3500"
                data-aos-once="true"
                className="relative p-2 lg:w-1/3 md:w-1/2 w-full"
              >
                <Slider {...settings}>
                  <div>
                    <img src={Crewing1} alt="" />
                  </div>
                  <div>
                    <img src={Crewing2} alt="" />
                  </div>
                  <div>
                    <img src={Crewing3} alt="" />
                  </div>
                  <div>
                    <img src={Crewing4} alt="" />
                  </div>
                </Slider>
                <div className="absolute inset-x-[8px] bottom-[15px] px-6 pb-[1.2rem] bg-gradient-to-t from-[#2d2d2d] to-transparent">
                  <p className="text-sm   tracking-wide text-white">Crewing</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Partners />
      </div>
      <Footer />
    </div>
  );
}

export default Media;
