import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import Services from "./pages/Services";
import ContractStaffing from "./pages/ContractStaffing";
import EngineeringDesign from "./pages/EngineeringDesign";
import ProjectManagement from "./pages/ProjectManagement";
import Procurement from "./pages/Procurement";
import GasparkStory from "./pages/GasparkStory";
import Media from "./pages/Media";
import Loader from "./components/Loader";
import ScrollToTop from "./components/ScrollToTop";
import Careers from "./pages/Careers";
import toast, { Toaster } from 'react-hot-toast';

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    // Simulating an asynchronous operation
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <div className="App">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Toaster/>
          <ScrollToTop />
          <Routes>
            {/* Main Routes */}
            <Route>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contract-staffing" element={<ContractStaffing />} />
              <Route
                path="/engineering-design"
                element={<EngineeringDesign />}
              />
              <Route
                path="/project-management"
                element={<ProjectManagement />}
              />
              <Route path="/procurement" element={<Procurement />} />
              <Route path="/gaspark-story" element={<GasparkStory />} />
              <Route path="/media" element={<Media />} />
              <Route path="/careers" element={<Careers />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      )}
    </div>
  );
}

export default App;
