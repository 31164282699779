import React from "react";
import Paul from "../../assets/images/paul.webp";
import PaulStoryImg from "../../assets/images/paulstory.webp";
import { useTranslation } from "react-i18next";

function PaulStory() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  return (
    <div>
      <>
        <div
          className="cursor-pointer"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-once="true"
          onClick={() => setShowModal(true)}
        >
          <div className="relative pb-[20rem] md:pb-56 mb-4 shadow lg:pb-64 overflow-hidden bg-cover bg-no-repeat">
            <img
              className="absolute object-cover w-full transition duration-500 ease-in-out hover:scale-110"
              src={Paul}
              alt="Person"
            />
          </div>
          <div className="flex justify-between  sm:text-left">
            <div>
              <p className="text-lg font-bold">Paul Jerry Kwofie</p>
              <p className="mb-4 md:mb-0 text-xs text-gray-800">
                {t("QHSE_Manager")}
              </p>
            </div>
          </div>
        </div>
        {showModal ? (
          <>
            <div
              className="justify-center my-4 lg:my-10 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <div
                className="relative w-auto my-6 mx-auto max-w-7xl"
                onClick={() => setShowModal(false)}
              >
                {/*content*/}

                <div className="bg-white border border-gray-200 shadow">
                  <a href="#">
                    <img className="" src={PaulStoryImg} alt="" />
                  </a>
                  <div className="p-5 lg:p-10">
                    <a href="#">
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                        Paul Jerry Kwofie
                      </h5>
                    </a>
                    <p className="mb-8">{t("QHSE_Manager")}</p>
                    <p className="mb-3 font-normal text-gray-700">
                    {t("Paul_Bio")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    </div>
  );
}

export default PaulStory;
