import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Success from "../assets/icons/sucess.gif";
import React from "react";
import toast from "react-hot-toast";

export default function ToastMessage(): JSX.Element {
  return (
    <>
      {toast.custom((t) => (
        <div className={`${t.visible ? "animate-enter" : "animate-leave"} `}>
          {/* Global toaster location */}
          <div
            aria-live="assertive"
            className="fixed inset-0 w-full h-[100vh] flex items-center justify-center p-6"
          >
            <div className="flex flex-col items-end space-y-6">
              {/* Toasts */}
              <div className="relative max-w-lg rounded-xl bg-white shadow-xl p-4">
                <div className="">
                  <img className="mx-auto" src={Success} alt="" />
                  <div>
                    <div className="mt-2 text-2xl text-center font-semibold text-gray-800">
                      Submitted!
                    </div>
                    <div className="mt-2 text-lg text-center  text-gray-800 font-medium">
                      Your request has been sent! You'll hear from us shortly.
                    </div>
                  </div>
                </div>

                <button
                  onClick={() => toast.dismiss(t.id)}
                  type="button"
                  className="absolute z-90 top-3 right-3 inline-flex cursor-pointer items-center justify-center rounded-xl p-2"
                >
                  <p className="text-2xl">X</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
