import React, { useEffect } from "react";
import { NavbarHome } from "../components/NavbarHome";
import Project from "../assets/icons/settingswhite.svg";
import { Footer } from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import CallModal3 from "../components/ContactModal/CallModal3";
import { useTranslation } from "react-i18next";

function ProjectManagement() {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
      AOS.refresh();
    });
  }, []);
  return (
    <div>
      <div className="bggProject">
        <NavbarHome />
        <div>
          <section className="text-gray-100">
            <div className="container mx-auto flex flex-col items-left px-4 py-16 text-left md:pb-36 md:px-6  lg:max-w-7xl">
              <img
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
                src={Project}
                className="w-14 pb-16"
                alt=""
              />
              <h1
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-once="true"
                className="text-3xl md:text-4xl font-bold lg:text-5xl"
              >
                {t("Project_Management")}
              </h1>
            </div>
          </section>
        </div>
      </div>

      <section className="px-4 sm:px-8">
        <div className="mx-auto max-w-7xl space-y-8">
          <h2
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-once="true"
            className="text-left lg:w-[40rem] text-xl lg:text-3xl font-bold my-6 md:m-6"
          >
            {t("From_Seamless")}
          </h2>

          <div className="max-w-2xl mx-auto text-gray-800">
            <ul className="space-y-12">
              <li className="flex items-start space-x-3">
                <div className="flex-1 space-y-2">
                  <div
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    <p className="py-2">{t("Gaspark_excel")}</p>
                    <p className="py-2">{t("We_offer_coaching")}</p>
                    <p className="py-2">{t("Gaspark_is_committed")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="max-w-2xl mx-auto pb-24">
            <CallModal3 />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ProjectManagement;
