import RansfordStory from "./RansfordStory";
import SelasieStory from "./SelasieStory";
import PaulStory from "./PaulStory";
import OscarStory from "./OscarStory";

export const CoreTeam = () => {
  return (
    <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-4">
        <RansfordStory />
        <SelasieStory />
        <PaulStory />
        <OscarStory />
      </div>
    </div>
  );
};
