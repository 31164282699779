import { useState } from "react";
import Logo from "../assets/icons/logo.svg";
import { NavLink } from "react-router-dom";
import Drawer from "./Drawer";
import NavDropDown from "./NavDropDown";
import LangSwitcher from "./Translations/LangSwitcher";
import { useTranslation } from "react-i18next";

export const NavbarHome = () => {
  const { t } = useTranslation();
  return (
    <div className="px-4 pt-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <header className="bg-transparent text-white pb-4">
        <div className="container flex justify-between h-16 mx-auto">
          <div className="flex justify-between  w-full">
            <a href="/" className="flex items-center ">
              <img src={Logo} alt="" />
            </a>
            <div className="items-center flex-shrink-0 hidden lg:flex">
              <p className="self-center px-8 py-3 rounded">
                +233 (0) 55 957 8497
              </p>
              <button className="self-center px-2 py-2 font-semibold  bg-[#1A428A] text-white">
                <LangSwitcher />
              </button>
            </div>
          </div>
          <Drawer />
        </div>
      </header>
      <section className="hidden lg:block lg:bg-transparent font-normal lg:text-white">
        <div className="container flex flex-col mx-auto lg:flex-row divide-gray-400">
          <ul className="self-center py-2 space-y-4 sm:flex sm:space-y-0 sm:justify-around sm:space-x-4 lg:flex-1 lg:justify-between">
            <li className="relative pt-8 border-white w-48 border-opacity-50 border-t-[0.1px] hover:border-opacity-0 after:absolute after:bg-gray-200 after:top-0 after:left-0 after:h-[0.1px] after:w-full after:origin-top-right after:scale-x-0 hover:after:origin-top-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300">
              <NavLink className="text-left" to="/">
                {t("Home")}
              </NavLink>
            </li>
            <li className="relative pt-8 border-white w-48 border-opacity-50 border-t-[0.1px] hover:border-opacity-0 after:absolute after:bg-gray-200 after:top-0 after:left-0 after:h-[0.1px] after:w-full after:origin-top-right after:scale-x-0 hover:after:origin-top-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300">
              <NavDropDown />
            </li>
            <li className="relative pt-8 border-white w-48 border-opacity-50 border-t-[0.1px] hover:border-opacity-0 after:absolute after:bg-gray-200 after:top-0 after:left-0 after:h-[0.1px] after:w-full after:origin-top-right after:scale-x-0 hover:after:origin-top-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300">
              <NavLink className="text-left" to="/gaspark-story">
                {t("The_Gaspark_Story")}
              </NavLink>
            </li>
            <li className="relative pt-8 border-white w-48 border-opacity-50 border-t-[0.1px] hover:border-opacity-0 after:absolute after:bg-gray-200 after:top-0 after:left-0 after:h-[0.1px] after:w-full after:origin-top-right after:scale-x-0 hover:after:origin-top-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300">
              <a
                className="text-left"
                href="https://gasparkgh.com/insights/"
         
              >
                {t("Insights")}
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};
