import React, { useEffect } from "react";
import { NavbarHome } from "../components/NavbarHome";
import Partners from "../components/Partners";
import { CoreServices } from "../components/CoreServices";
import { Footer } from "../components/Footer";
import Map from "../components/Map";
import CallModal1 from "../components/ContactModal/CallModal1";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import RandomImage from "../components/RandomImage";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
      AOS.refresh();
    });
  }, []);
  return (
    <div>
      <div className="bgg">
        <NavbarHome />
        <div>
          <div className=" flex items-center justify-center w-full">
            <section className="">
              <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-6 ">
                <div className="grid max-w-lg grid-cols-1 mx-auto lg:max-w-full lg:items-center lg:grid-cols-2 gap-y-12 lg:gap-x-24">
                  <div className="pt-16 lg:pt-0">
                    <div className="text-center lg:text-left ">
                      <h1
                        data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-once="true"
                        className="text-4xl font-bold leading-tight text-white sm:text-5xl sm:leading-tight lg:leading-tight lg:text-[3.2rem]"
                      >
                        {t("Hero_text_1")}
                      </h1>
                      <p
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        className="font-normal mt-2 text-sm sm:text-base text-white"
                      >
                        {t("Hero_text_2")}
                      </p>

                      <div className="flex items-center justify-center mt-4 mb-6 py-2 lg:justify-start">
                        <CallModal1 />
                      </div>
                    </div>
                  </div>
                  <div>
                    <RandomImage />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* Gaspark is all over the globe! */}

      <section className="px-4 sm:px-8 py-6 md:py-18">
        <div className="mx-auto max-w-[75rem] space-y-8">
          <div className="py-6 py-6 border-b-[0.2px] border-b-[#A9A9A9]">
            <div className="container mx-auto">
              <div className="flex flex-col lg:flex-row items-center justify-between">
                <h2 className="text-center text-xl tracki font-normal">
                {t("Gaspark_is_all_over_the_globe!")}
                </h2>
              </div>
            </div>
          </div>
          <div className="flex flex-col-reverse items-center justify-between gap-6 lg:flex-row-reverse">
            <div className="max-w-2xl space-y-6 text-center lg:text-left">
              <p className="text-md font-normal">
              {t("We_are_proud")}
              </p>
            </div>

            <div className="max-w-lg">
              <h1 className="text-6xl text-[#202631] font-bold">
                <>
                  <CountUp start={1500} end={2000} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  +
                </>
              </h1>
              <h1 className="text-md"> {t("Engineers_Worldwide")}</h1>
            </div>
          </div>
          <Map />
        </div>
      </section>

      {/* Gaspark is all over the globe! */}
      <Partners />

      {/* Our Core Services! */}
      <section className="sm:px-8 mb-24">
        <div className="mx-auto max-w-[75rem] space-y-8">
          <div className="py-6 border-b-[0.2px] border-b-[#A9A9A9]">
            <div className="container mx-auto">
              <div className="flex flex-col lg:flex-row items-center justify-between">
                <h1 className="text-center text-3xl md:text-4xl lg:text-5xl  font-bold">
                {t("Our_Core_Services")}
                </h1>
              </div>
            </div>
          </div>
          <CoreServices />
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
