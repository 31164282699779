import React, { useState } from "react";
import Arrowleft from "../../assets/icons/arrow-left.svg";
import toast from "react-hot-toast";
import ToastMessage from "../ToastMessage";
import { useTranslation } from "react-i18next";

export default function CallModal() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const [service, setService] = useState<string[]>([]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (service.includes(value)) {
      setService(service.filter((option) => option !== value));
    } else {
      setService([...service, value]);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "8c186e92-11d6-47ca-9c9c-a588a16e425d");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      setShowModal(false);
      toast.custom(
        <div>
          <ToastMessage />
        </div>
      );

      // window.location.reload();
      console.log("Success", res);
    }
  };

  return (
    <>
      <button
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-duration="1500"
        data-aos-once="true"
        onClick={() => setShowModal(true)}
        className="group [transform:translateZ(0)] flex items-center px-4 md:px-6 mt-4 py-3 border-2 hover:border-[#1A428A] bg-transparent overflow-hidden relative before:absolute before:bg-[#1A428A] before:bottom-0 before:left-0 before:h-full before:w-full before:-translate-x-full hover:before:translate-x-0 before:transition before:ease-in-out before:duration-500"
      >
        <span className=" relative z-0 text-white xl:text-2xl group-hover:text-gray-200 transition ease-in-out duration-500 ">
          {t("Request_a_call")}
        </span>
        <img
          className="p-2 relative z-90 translate-x-0 group-hover:translate-x-2 transition-transform ease-in-out duration-200"
          src={Arrowleft}
          alt=""
        />
      </button>
      {showModal ? (
        <>
          <div className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-6xl">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between bg-[#1A428A] p-8">
                  <h3 className="text-3xl text-white font-normal">
                    {t("We_typically")}
                  </h3>
                  <button
                    className=" ml-auto  border-0  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="z-90 text-white hover:bg-opacity-20  hover:bg-black hover:rounded-full h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <h1 className="my-4 text-grey-700 text-xl leading-relaxed">
                    {t("Tell_us_a_few_things")}
                  </h1>

                  <div className="flex items-center justify-center ">
                    <div className="mx-auto w-full max-w-6xl ">
                      <form onSubmit={onSubmit}>
                        <div className="-mx-3 flex flex-wrap">
                          <div className="w-full px-3 sm:w-[50%]">
                            <div className="mb-5">
                              <input
                                type="text"
                                name="full name"
                                id="fName"
                                placeholder={`${t("What_should_we")}`}
                                className="w-full placeholder:font-normal bg-[#EEEEEE] py-6 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1]"
                                required
                              />
                            </div>
                          </div>
                          <div className="w-full px-3 sm:w-[50%]">
                            <div className="mb-5">
                              <input
                                type="text"
                                name="business name"
                                id="lName"
                                placeholder={`${t("What's_your_business")}`}
                                className="w-full bg-[#EEEEEE] placeholder:font-normal py-6 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1]"
                                required
                              />
                            </div>
                          </div>
                          <div className="w-full px-3 sm:w-[50%]">
                            <div className="mb-5">
                              <input
                                type="text"
                                name="phone number"
                                id="fName"
                                placeholder={`${t("Phone_Number")}`}
                                className="w-full placeholder:font-normal bg-[#EEEEEE] py-6 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1]"
                                required
                              />
                            </div>
                          </div>
                          <div className="w-full px-3 sm:w-[50%]">
                            <div className="mb-5">
                              <input
                                type="email"
                                name="email"
                                id="lName"
                                placeholder={`${t("Email_Address")}`}
                                className="w-full bg-[#EEEEEE] placeholder:font-normal py-6 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1]"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <h1 className="my-4 text-grey-700 text-xl leading-relaxed">
                          {t("Which_services")}
                        </h1>
                        <div className="hidden w-full pr-3">
                          <div className="mb-5">
                            <input
                              value={service}
                              type="text"
                              name="service"
                              id="lName"
                              className="w-full bg-[#EEEEEE] placeholder:font-normal py-6 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1]"
                              required
                            />
                          </div>
                        </div>
                        <div className="flex flex-wrap">
                          <div className="flex items-center m-1 border-2 border-[#EEEEEE] ">
                            <input
                              id="staffing"
                              type="checkbox"
                              value="Staffing"
                              name="staffing"
                              className="w-4 h-4 ml-4 bg-gray-100 border-gray-300 "
                              onChange={handleOptionChange}
                            />
                            <label
                              htmlFor="staffing"
                              className="label ml-2  py-4 pr-4 text-sm font-medium text-gray-900"
                            >
                              {t("Staffing")}
                            </label>
                          </div>
                          <div className="flex items-center m-1 border-2 border-[#EEEEEE]">
                            <input
                              id="fabrication"
                              type="checkbox"
                              value="Fabrication"
                              name="fabrication"
                              className="w-4 h-4 ml-4 text-green-600 bg-gray-100 border-gray-300 "
                              onChange={handleOptionChange}
                            />
                            <label
                              htmlFor="fabrication"
                              className="label ml-2  py-4 pr-4 text-sm font-medium text-gray-900"
                            >
                              {t("Fabrication")}
                            </label>
                          </div>
                          <div className="flex items-center m-1 border-2 border-[#EEEEEE]">
                            <input
                              id="welding"
                              type="checkbox"
                              value="Welding"
                              name="welding"
                              className="w-4 h-4 ml-4 bg-gray-100 border-gray-300"
                              onChange={handleOptionChange}
                            />
                            <label
                              htmlFor="welding"
                              className="label ml-2 py-4 pr-4 text-sm font-medium text-gray-900"
                            >
                              {t("Welding")}
                            </label>
                          </div>
                          <div className="flex items-center m-1 border-2 border-[#EEEEEE]">
                            <input
                              id="surface-treatment"
                              type="checkbox"
                              value="Surface Treatment"
                              name="Surface Treatment"
                              className="w-4 h-4 ml-4 bg-gray-100 border-gray-300 "
                              onChange={handleOptionChange}
                            />
                            <label
                              htmlFor="surface-treatment"
                              className="label ml-2 py-4 pr-4 text-sm font-medium text-gray-900"
                            >
                              {t("Surface_Treatment")}
                            </label>
                          </div>
                          <div className="flex items-center m-1 border-2 border-[#EEEEEE]">
                            <input
                              id="rubber-lining"
                              type="checkbox"
                              value="Rubber Lining"
                              name="Rubber Lining"
                              className="w-4 h-4 ml-4 bg-gray-100 border-gray-300 "
                              onChange={handleOptionChange}
                            />
                            <label
                              htmlFor="rubber-lining"
                              className="label ml-2 py-4 pr-4 text-sm font-medium text-gray-900"
                            >
                              {t("Rubber_Lining")}
                            </label>
                          </div>
                          <div className="flex items-center m-1 border-2 border-[#EEEEEE]">
                            <input
                              id="conveyor-belt-installation"
                              type="checkbox"
                              value="Conveyor Belt Installation"
                              name="Conveyor Belt Installation"
                              className="w-4 h-4 ml-4 bg-gray-100 border-gray-300 "
                              onChange={handleOptionChange}
                            />
                            <label
                              htmlFor="conveyor-belt-installation"
                              className="label ml-2 py-4 pr-4 text-sm font-medium text-gray-900"
                            >
                              {t("Conveyor_Belt_Installation")}
                            </label>
                          </div>
                          <div className="flex items-center m-1 border-2 border-[#EEEEEE]">
                            <input
                              id="Erection of structures, piping mechanical units and tankage"
                              type="checkbox"
                              value="Erection of structures, piping mechanical units and tankage"
                              name="Erection of structures, piping mechanical units and tankage"
                              className="w-4 h-4 ml-4 bg-gray-100 border-gray-300 "
                              onChange={handleOptionChange}
                            />
                            <label
                              htmlFor="Erection of structures, piping mechanical units and tankage"
                              className="label ml-2 py-4 pr-4 text-left text-sm font-medium text-gray-900"
                            >
                              {t("Erection")}
                            </label>
                          </div>
                          <div className="flex items-center m-1 border-2 border-[#EEEEEE]">
                            <input
                              id="maintenance-works"
                              type="checkbox"
                              value=" Maintenance works"
                              name="Maintenance works"
                              className="w-4 h-4 ml-4 bg-gray-100 border-gray-300 "
                              onChange={handleOptionChange}
                            />
                            <label
                              htmlFor="maintenance-works"
                              className="label ml-2 py-4 pr-4 text-sm font-medium text-gray-900"
                            >
                              {t("Maintenance_works")}
                            </label>
                          </div>
                          <div className="flex items-center m-1 border-2 border-[#EEEEEE]">
                            <input
                              id="all-services"
                              type="checkbox"
                              value="All Services"
                              name="All Services"
                              className="w-4 h-4 ml-4 bg-gray-100 border-gray-300 "
                              onChange={handleOptionChange}
                            />
                            <label
                              htmlFor="all-services"
                              className="label ml-2 py-4 pr-4 text-sm font-medium text-gray-900"
                            >
                              {t("All_Services")}
                            </label>
                          </div>
                          <div className="flex items-center m-1 border-2 border-[#EEEEEE]">
                            <input
                              id="other"
                              type="checkbox"
                              value="Other"
                              name="Other"
                              className="w-4 h-4 ml-4 bg-gray-100 border-gray-300 "
                              onChange={handleOptionChange}
                            />
                            <label
                              htmlFor="other"
                              className="label ml-2 py-4 pr-4 text-sm font-medium text-gray-900"
                            >
                              {t("Other")}
                            </label>
                          </div>
                        </div>
                        <div className="flex items-center justify-center lg:justify-end p-6 m-1 border-t border-solid border-slate-200 rounded-b">
                          <button
                            type="submit"
                            className="group [transform:translateZ(0)] flex items-center px-6 py-3 hover:border-white  group-hover:bg-none bg-[#1A428A] overflow-hidden relative before:absolute before:bg-none before:border-2 before:border-white before:bottom-0 before:left-0 before:h-full before:w-full before:-translate-x-full hover:before:translate-x-0 before:transition before:ease-in-out before:duration-500"
                          >
                            <span className="relative z-0 text-white group-hover:text-gray-200 transition ease-in-out duration-500">
                              {t("Request_a_call")}
                            </span>
                            <img
                              className="p-2 relative z-90 translate-x-0 group-hover:translate-x-2 transition-transform ease-in-out duration-200"
                              src={Arrowleft}
                              alt=""
                            />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
