import { RiArrowRightSLine } from "react-icons/ri";
import ContStaff from "../assets/icons/helmet.svg";
import Engineer from "../assets/icons/crane.svg";
import Project from "../assets/icons/settings.svg";
import Procurement from "../assets/icons/procre.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CoreServices = () => {
  const { t } = useTranslation();
  return (
    <div className=" mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl ">
      <div className="grid grid row-gap-5 lg:grid-cols-3 gridder lg:griddery">
        <Link
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-once="true"
          className="group [transform:translateZ(0)]"
          to="/contract-staffing"
        >
          <div className="relative lg:p-px overflow-hidden hover:bg-opacity-0 lg:mr-1 lg:mb-1">
            <div className="contractbg">
              <div className="bg-[#EEEEEE] bg-opacity-0 lg:bg-opacity-100 group-hover:bg-opacity-0 group-hover:transition group-hover:ease-in-out group-hover:duration-500">
                <div className="relative p-4 md:p-8 ">
                  <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
                    <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full lg:mb-0">
                      <img
                        className=" translate-y-0 duration-500 group-hover:-translate-y-24 group-hover:duration-500"
                        src={ContStaff}
                        alt=""
                      />
                    </div>
                  </div>
                  <p className="mt-16 mb-8 group-hover:text-white text-base text-white lg:text-[#1A428A]">
                  {t("CONTRACT_STAFFING")}
                  </p>
                  <RiArrowRightSLine className="inline-flex rounded-full border-2 border-[#1A428A]  items-center text-3xl font-semibold group-hover:text-white text-white lg:text-[#1A428A] bg-[#1A428A] lg:bg-transparent group-hover:bg-[#1A428A]" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-once="true"
          className="group [transform:translateZ(0)]"
          to="/engineering-design"
        >
          <div className="relative lg:p-px overflow-hidden hover:bg-opacity-0 lg:mr-1 lg:mb-1">
            <div className="engineerbg">
              <div className="bg-[#EEEEEE] bg-opacity-0 lg:bg-opacity-100 group-hover:bg-opacity-0 group-hover:transition group-hover:ease-in-out group-hover:duration-500">
                <div className="relative p-4 md:p-8 rounded-sm">
                  <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
                    <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full lg:mb-0">
                      <img
                        className=" translate-y-0 duration-500 group-hover:-translate-y-24 group-hover:duration-500"
                        src={Engineer}
                        alt=""
                      />
                    </div>
                  </div>
                  <p className="mt-16 mb-8 group-hover:text-white text-base text-white lg:text-[#1A428A]">
                  {t("ENGINEERING_DESIGN")}
                  </p>
                  <RiArrowRightSLine className="inline-flex rounded-full border-2 border-[#1A428A]  items-center text-3xl font-semibold group-hover:text-white text-white lg:text-[#1A428A] bg-[#1A428A] lg:bg-transparent group-hover:bg-[#1A428A]" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-once="true"
          className="group [transform:translateZ(0)]"
          to="/project-management"
        >
          <div className="relative lg:p-px overflow-hidden hover:bg-opacity-0 lg:mr-1 lg:mb-1">
            <div className="projectbg">
              <div className="bg-[#EEEEEE] bg-opacity-0 lg:bg-opacity-100 group-hover:bg-opacity-0 group-hover:transition group-hover:ease-in-out group-hover:duration-500">
                <div className="relative p-4 md:p-8 rounded-sm">
                  <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
                    <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full lg:mb-0">
                      <img
                        className=" translate-y-0 duration-500 group-hover:-translate-y-24 group-hover:duration-500"
                        src={Project}
                        alt=""
                      />
                    </div>
                  </div>
                  <p className="mt-16 mb-8 group-hover:text-white text-base text-white lg:text-[#1A428A]">
                  {t("PROJECT_MANAGEMENT")}
                  </p>
                  <RiArrowRightSLine className="inline-flex rounded-full border-2 border-[#1A428A]  items-center text-3xl font-semibold group-hover:text-white text-white lg:text-[#1A428A] bg-[#1A428A] lg:bg-transparent group-hover:bg-[#1A428A]" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link
          data-aos="fade-up"
          data-aos-duration="2000"
          data-aos-once="true"
          className="group [transform:translateZ(0)]"
          to="/procurement"
        >
          <div className="relative lg:p-px overflow-hidden  hover:bg-opacity-0 lg:mr-1 lg:mb-1">
            <div className="procurebg">
              <div className="bg-[#EEEEEE] bg-opacity-0 lg:bg-opacity-100 group-hover:bg-opacity-0 group-hover:transition group-hover:ease-in-out group-hover:duration-500">
                <div className="relative p-4 md:p-8 rounded-sm">
                  <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
                    <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full lg:mb-0">
                      <img
                        className=" translate-y-0 duration-500 group-hover:-translate-y-24 group-hover:duration-500"
                        src={Procurement}
                        alt=""
                      />
                    </div>
                  </div>
                  <p className="mt-16 mb-8 group-hover:text-white text-base text-white lg:text-[#1A428A]">
                  {t("PROCUREMENT")} <span className="opacity-0">KKKKKKK</span>
                  </p>
                  <RiArrowRightSLine className="inline-flex rounded-full border-2 border-[#1A428A]  items-center text-3xl font-semibold group-hover:text-white text-white lg:text-[#1A428A] bg-[#1A428A] lg:bg-transparent group-hover:bg-[#1A428A]" />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
