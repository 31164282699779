import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { MdKeyboardArrowUp } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Logo from "../assets/icons/logo.svg";
import ContStaff from "../assets/icons/helmetwhite.svg";
import Engineer from "../assets/icons/cranewhite.svg";
import Project from "../assets/icons/settingswhite.svg";
import Procurement from "../assets/icons/procurewhite.svg";
import { useTranslation } from "react-i18next";
import LangSwitcher from "./Translations/LangSwitcher";

const Drawer = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="">
      <button onClick={toggleDrawer} className="p-4 lg:hidden">
        {/* {isOpen ? 'Close' : 'Open'} */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6 dark:text-gray-100"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </button>
      <div
        className={`fixed inset-y-0 right-0 z-40 w-full bg-[#1A428A] shadow-lg transform transition-all duration-300 ease-in-out  ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="p-4">
          <div className="w-full flex justify-between items-center py-4 rounded-lg text-white ">
            <a href="/">
              {" "}
              <img src={Logo} alt="" />
            </a>

            <AiOutlineCloseCircle
              onClick={() => setIsOpen(false)}
              className="text-3xl"
            />
          </div>
          <ul className="space-y-2 font-medium">
            <li className=" border-b-[0.1px] border-[#EEEEEE] border-opacity-40">
              <NavLink
                to="/"
                className="flex text-white items-center nav-active py-4 rounded-lg "
              >
                <span className="ml-3 text-2xl font-semibold">
                  {" "}
                  {t("Home")}
                </span>
              </NavLink>
            </li>
            <li className=" border-b-[0.1px] border-[#EEEEEE] border-opacity-40">
              <NavLink
                to="/services"
                className=" items-center nav-active  rounded-lg text-white"
              >
                {/* <span className="flex-1 ml-3 whitespace-nowrap text-2xl font-semibold">
                  Our Services
                </span> */}
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="w-full flex justify-between items-center nav-active rounded-lg text-white">
                        <span className="ml-3 whitespace-nowrap text-2xl font-semibold">
                          {t("Our_Services")}
                        </span>
                        <MdKeyboardArrowUp
                          className={`${
                            open ? "rotate-180 text-heading " : "text-text"
                          } h-5 w-12 my-5`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className=" py-2">
                        <Link
                          to="/contract-staffing"
                          className=" inline-flex w-full items-center p-2 text-sm "
                        >
                          <img className="w-8 m-1" src={ContStaff} alt="" />

                          <p className="m-1 text-base text-white ">
                            {t("CONTRACT_STAFFING")}
                          </p>
                        </Link>
                        <Link
                          to="/engineering-design"
                          className="inline-flex w-full items-center p-2 text-sm"
                        >
                          <img className="w-8 m-1" src={Engineer} alt="" />
                          <p className="m-1 text-base text-white">
                            {t("ENGINEERING_DESIGN")}
                          </p>
                        </Link>
                        <Link
                          to="/project-management"
                          className="inline-flex w-full items-center p-2 text-sm"
                        >
                          <img className="w-8 m-1" src={Project} alt="" />
                          <p className="m-1 text-base text-white">
                            {t("PROJECT_MANAGEMENT")}
                          </p>
                        </Link>
                        <Link
                          to="/procurement"
                          className="inline-flex w-full items-center p-2 text-sm"
                        >
                          <img className="w-8 m-1" src={Procurement} alt="" />
                          <p className="m-1 text-base text-white">
                            {t("PROCUREMENT")}
                          </p>
                        </Link>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </NavLink>
            </li>
            <li className=" border-b-[0.1px] border-[#EEEEEE] border-opacity-40">
              <NavLink
                to="/gaspark-story"
                className="flex text-white items-center nav-active py-4 rounded-lg "
              >
                <span className="ml-3 text-2xl font-semibold">
                  {t("The_Gaspark_Story")}
                </span>
              </NavLink>
            </li>
            <li className=" border-b-[0.1px] border-[#EEEEEE] border-opacity-40">
              <a
                href="https://gasparkgh.com/insights/"
                target="_blank"
                rel="noreferrer noopener"
                className="flex text-white items-center nav-active py-4 rounded-lg "
              >
                <span className="ml-3 text-2xl font-semibold">Insights</span>
              </a>
            </li>
          </ul>
          <div className="items-center flex-shrink-0 mt-16">
            <p className="self-center ml-3 py-3 rounded">
              +233 (0) 55 957 8497
            </p>
            <button className="self-center  ml-3  py-2 font-semibold rounded bg-[#1A428A] text-white">
              <LangSwitcher />
            </button>
          </div>
        </div>
      </div>
      <div
        onClick={toggleDrawer}
        className={`${
          isOpen ? "fixed" : "hidden"
        } inset-0 z-30 bg-black opacity-25 transition-opacity`}
      />
    </div>
  );
};

export default Drawer;
