import React, { useEffect } from "react";
import { NavbarHome } from "../components/NavbarHome";
import Map from "../components/Map";
import { CoreTeam } from "../components/CoreTeam/CoreTeam";
import { Footer } from "../components/Footer";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import AOS from "aos";
import "aos/dist/aos.css";
import StoryMap from "../components/StoryMap";
import { useTranslation } from "react-i18next";

function GasparkStory() {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
      AOS.refresh();
    });
  }, []);
  return (
    <div>
      <div className="bggStory">
        <NavbarHome />
        <div>
          <section className="text-gray-100">
            <div className="container mx-auto flex flex-col items-left px-4 py-36 text-left md:px-6 lg:max-w-7xl">
              <h1
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
                className="text-4xl font-bold leadi sm:text-5xl"
              >
                {t("A_combined")}
              </h1>
            </div>
          </section>
        </div>
      </div>
      <section className="px-4 sm:px-8">
        <div className="mx-auto max-w-7xl space-y-8">
          <h2
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="true"
            className="text-left text-2xl lg:text-4xl font-bold m-6"
          >
            {t("The_Gaspark_Story")}
          </h2>

          <section className="pt-4 pb-12 bg-white overflow-hidden">
            <div className="container px-4 mx-auto">
              <div className="flex flex-wrap -m-8">
                <div className="w-full md:w-1/2 p-8">
                  <div
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-once="true"
                    className="group cursor-pointer"
                  >
                    <h3 className="mb-4 text-2xl lg:text-3xl text-[#1A428A] font-bold">
                      {t("Our")}{" "}
                      <span className="text-[#CF3535]">{t("mission")}</span>{" "}
                      {t("Our_Mission")}
                    </h3>
                    <p className="text-2xl lg:text-3xl text-[#1A428A] font-bold">
                      {t("Our")}{" "}
                      <span className="text-[#CF3535]"> {t("vision")} </span>{" "}
                      {t("Our_Vision")}
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-8">
                  <div
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-once="true"
                    className="group cursor-pointer"
                  >
                    <p className="text-base pb-2">
                     {t("Gaspark_Company_Limited,_a_proudly")}
                    </p>
                    <p className="text-base py-2">
                     {t("Renowned_for_our_excellence")}
                    </p>
                    <p className="text-base py-2">
                    {t("At_Gaspark,_our_core_management")}
                    </p>
                    <p className="text-base py-2">
                     {t("While_quality_is_our_guiding_principle")}
                    </p>
                    <p className="text-base py-2">
                     {t("We_continually_strive_for_excellence")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="flex flex-col-reverse items-center justify-between gap-6 lg:flex-row-reverse">
            <div className="max-w-2xl space-y-6 text-center lg:text-left">
              <p
                data-aos="fade-left"
                data-aos-duration="2000"
                data-aos-once="true"
                className="text-md text-[#4E4E4E] font-normal"
              >
                {t("We_are_proud")}
              </p>
            </div>

            <div className="max-w-lg">
              <h1 className="text-6xl text-[#202631] font-bold">
                {" "}
                <>
                  <CountUp start={1500} end={2000} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  +
                </>
              </h1>
              <h1 className="text-md">{t("Engineers_Worldwide")}</h1>
            </div>
          </div>
          <StoryMap />
        </div>
      </section>
      <section className="px-4 sm:px-8 py-14">
        <div className="mx-auto max-w-7xl space-y-8">
          <h2 className="text-left text-3xl md:text-4xl font-bold m-6">
          {t("The_core_team")}
          </h2>
          <CoreTeam />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default GasparkStory;
