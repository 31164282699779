import React, { ChangeEvent } from "react";
import "./i18n";
import { useTranslation } from "react-i18next";
import { availableLanguages } from "./i18n";

function LangSwitcher() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <select className="bg-[#1A428A] text-white"
        defaultValue={i18n.language}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
      >
        {availableLanguages.map((language) => (
          <option key={language}>{language}</option>
        ))}
      </select>
    </div>
  );
}

export default LangSwitcher;