import React from 'react'
import CircleMap from "../assets/icons/circleMap.svg";
import CircleBig from "../assets/icons/Group 56.svg";
import circlemobile from "../assets/icons/circlebig.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Mappy from "../assets/images/maproject.webp";
import { Link } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { useTranslation } from "react-i18next";

function StoryMap() {
  const { t } = useTranslation();
    var settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
      };
  return (
    <div>
       <div className="map">
        <div className="hidden md:h-[85vh] md:w-full md:grid md:grid-cols-2 md:gap-2 md:items-center md:justify-between">
          <div>
            <div className="flex mt-32 items-center justify-start">
              <hr
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-once="true"
                className="h-[1.6px]  bg-[#1A428A] w-full"
              />
              <img
                data-aos="zoom-in"
                data-aos-duration="1500"
                data-aos-once="true"
                src={CircleMap}
                alt=""
              />
            </div>
            <p>
            {t("Head_Office")} <br /> Ghana
            </p>
          </div>
          <div className="relative flex mb-48  flex-col items-center justify-end">
            <img
              data-aos="zoom-in"
              data-aos-duration="1500"
              data-aos-once="true"
              className="h-full z-10"
              src={CircleBig}
              alt=""
            />
            <div
              data-aos="fade-down"
              data-aos-duration="2000"
              data-aos-once="true"
              className="absolute md:mr-[-2.7rem] md:mb-[-10.7rem] lg:mr-[-3.7rem] lg:mb-[-14.5rem] xl:mr-[-4.9rem] xl:mb-[-18.9rem] w-[87%] "
            >
              <Slider {...settings}>
                <div>
                  <img src={Mappy} alt="" />
                </div>
                <div>
                  <img src={Mappy} alt="" />
                </div>
                <div>
                  <img src={Mappy} alt="" />
                </div>
              </Slider>
              <div className="absolute inset-x-0 bottom-0 px-6 mb-[2.2rem]">
                <Link
                  to="/media"
                  className="text-sm underline  tracking-wide text-white"
                >
                 {t("View_more_projects")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="md:hidden">
          <div className="  py-10 w-full grid grid-cols-2 gap-2 items-center justify-between">
            <div className="pl-4 flex flex-row-reverse">
              <p className="text-sm">
                Head Office <br /> Ghana
              </p>
              <div className="flex flex-col  items-center justify-start">
                <hr className="h-[200px] w-[1.5px]  bg-[#1A428A] " />
                <img  
                data-aos="zoom-in"
                data-aos-duration="1900"
                data-aos-once="true" src={CircleMap} alt="" />
              </div>
            </div>
            <div className="flex pr-4 flex-col-reverse items-center justify-end">
             
              <img data-aos="zoom-in"
                data-aos-duration="2000"
                data-aos-once="true" src={circlemobile} alt="" />
            </div>
          </div>
          <div className="flex mt-4 items-center justify-center">
            <MdArrowForwardIos className="bg-[#1A428A] p-2 text-2xl rounded-full text-white" />
            <Link to="/media" className="px-2 ">
              View our project
            </Link>
          </div>
        </div> */}
         <div className="md:hidden">
          <div className="  py-10 w-full grid mb-24 ml-4 items-center justify-center">
            <div className=" flex flex-row-reverse">
              <p className="text-sm font-bold">
              {t("Head_Office")} <br /> Ghana
              </p>
              <div className="flex flex-col  items-center justify-start">
                <hr className="h-[200px] w-[1px]  bg-[#1A428A] " />
                <img  
                data-aos="zoom-in"
                data-aos-duration="1900"
                data-aos-once="true" src={CircleMap} alt="" />
              </div>
            </div>
            {/* <div className="flex pr-4 flex-col-reverse items-center justify-end"> */}
              {/* <hr className="h-[100px] w-[1.5px]  bg-[#1A428A] " /> */}
               {/* <img data-aos="zoom-in"
                data-aos-duration="2000"
                data-aos-once="true" src={circlemobile} alt="" /> */}
            {/* </div> */} 
            <img className="w-28 h-28 absolute right-[60px]" data-aos="zoom-in"
                data-aos-duration="2000"
                data-aos-once="true" src={circlemobile} alt="" />
          </div>
          <div className="flex mt-4 items-center justify-center">
            <MdArrowForwardIos className="bg-[#1A428A] p-2 text-2xl rounded-full text-white" />
            <Link to="/media" className="px-2 ">
            {t("View_more_projects")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryMap
