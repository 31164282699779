import React, { useEffect } from "react";
import { NavbarHome } from "../components/NavbarHome";
import HelmetWhite from "../assets/icons/helmetwhite.svg";
import { Footer } from "../components/Footer";
import Crawl from "../assets/images/vsessel crew.webp";
import Cargo from "../assets/images/large cargo.webp";
import Bullet from "../assets/icons/bullet.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import CallModal3 from "../components/ContactModal/CallModal3";
import Eng1 from "../assets/engineers/eng1.webp";
import Eng2 from "../assets/engineers/eng2.webp";
import Eng3 from "../assets/engineers/eng3.webp";
import Eng4 from "../assets/engineers/eng4.webp";
import Eng5 from "../assets/engineers/eng5.webp";
import Eng7 from "../assets/engineers/eng7.webp";
import Eng8 from "../assets/engineers/eng8.webp";
import Eng9 from "../assets/engineers/eng9.webp";
import Eng10 from "../assets/engineers/eng10.webp";
import Eng11 from "../assets/engineers/eng11.webp";
import Eng12 from "../assets/engineers/eng12.webp";
import Eng13 from "../assets/engineers/eng13.webp";
import Eng14 from "../assets/engineers/eng14.webp";
import Eng15 from "../assets/engineers/eng15.webp";
import Eng16 from "../assets/engineers/eng16.webp";
import Eng17 from "../assets/engineers/eng17.webp";
import { useTranslation } from "react-i18next";

function ContractStaffing() {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
      AOS.refresh();
    });
  }, []);
  return (
    <div>
      <div className="bggContract">
        <NavbarHome />
        <div>
          <section className="text-gray-100">
            <div className="container mx-auto flex flex-col items-left px-4 py-16 text-left md:pb-36 md:px-6 lg:max-w-7xl">
              <img
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
                src={HelmetWhite}
                className="w-14  pb-16"
                alt=""
              />
              <h1
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-once="true"
                className="text-3xl md:text-4xl font-bold  lg:text-5xl"
              >
                {t("Contract_Staffing_Heading")}
              </h1>
            </div>
          </section>
        </div>
      </div>

      <section className="px-4">
        <div className="mx-auto max-w-7xl space-y-8">
          <h2
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-once="true"
            className="text-left text-xl lg:text-3xl font-bold my-6 md:m-6"
          >
            {t("Gaspark_engineers_are_all_over_the_globe!")}
          </h2>

          <div className="flex flex-col items-left justify-left">
            <div id="scroll-container">
              <div id="scroll-text">
                <div className="flex -space-x-4">
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng1}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng2}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng3}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng4}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng5}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng7}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng8}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng9}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng10}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng11}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng12}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng13}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng14}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng15}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng16}
                  />
                  <img
                    alt=""
                    className="w-24 h-24 border-4 border-white rounded-full"
                    src={Eng17}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-2xl mx-auto text-gray-800">
            <ul className="space-y-12">
              <li className="flex items-start space-x-3">
                <div className="flex-1 space-y-2 mt-8 mb-4">
                  <div>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-once="true"
                    >
                      {t("Contract_Staffing_Subtext1")}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="lg:max-w-2xl mx-auto mt-4 lg:mt-0">
            <CallModal3 />
          </div>

          <section className="py-8">
            <div className="mx-auto max-w-6xl space-y-16">
              <div className="flex flex-col justify-between gap-6 lg:flex-row-reverse">
                <div
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  className="lg:max-w-xl space-y-6 text-left"
                >
                  <h3 className="text-3xl font-semibold lg:text-4xl">
                    {t("Crewing_for_vessels")}
                  </h3>
                  <p className="text-base text-gray-800 font-normal">
                    {t("Contract_Staffing_Subtext2")}
                  </p>
                </div>

                <div
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  className="max-w-lg relative overflow-hidden bg-cover bg-no-repeat"
                >
                  <img
                    src={Crawl}
                    alt=""
                    className="w-full h-full aspect-video transition duration-500 ease-in-out hover:scale-110"
                  />
                </div>
              </div>

              <div className="flex flex-col justify-between gap-6 lg:flex-row ">
                <div
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  className="lg:max-w-xl space-y-6 text-left"
                >
                  <h3 className="text-3xl font-semibold  lg:text-4xl">
                    {t("Shipowner's_package")}
                  </h3>
                  <p className="my-2 text-base text-gray-800 font-normal">
                    {t("Contract_Staffing_Subtext3")}
                  </p>
                  <p className="my-2 text-base text-gray-800 font-normal">
                    {t("Contract_Staffing_Subtext4")}
                  </p>
                  <p className="my-2 text-base text-gray-800 font-normal">
                    {t("Contract_Staffing_Subtext5")}
                  </p>
                </div>

                <div
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  className="max-w-lg relative overflow-hidden bg-cover bg-no-repeat"
                >
                  <img
                    src={Cargo}
                    alt=""
                    className=" transition duration-500 ease-in-out hover:scale-110"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="text-gray-800">
            <div className="container px-4 pt-12 pb-24 mx-auto space-y-8 lg:max-w-3xl">
              <div className="space-y-8">
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-once="true"
                >
                  <h3 className="mb-8 text-lg font-bold md:text-xl">
                    {t("Gaspark_provides_your_every_need.")}
                  </h3>
                  <ul className="space-y-4">
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t("Masters,_Chief_Mates,_2nd_and_3rd_Mates;")}
                        </h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t(
                            "Chief_Engineers,_Second_Engineers,_3rd_and_4th_engineers;"
                          )}
                        </h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t("Electrical_Engineers")}
                        </h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("We_can_provide")}</h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("offshore")}</h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t("Ro-Ro/Ro-Lo_vessels;")}
                        </h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("Bulk_Carriers;")}</h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t("Container/Containers_feeder_carriers;")}
                        </h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("Reefers")}</h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t("Multi-purpose,_Heavy-Lift_vessels;")}
                        </h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("General_Cargo")}</h4>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-once="true"
                >
                  <h3 className="mb-3 text-lg font-bold md:text-xl">
                    {t("We_also_provide_highly_qualified")}
                  </h3>
                  <ul className="space-y-4">
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t("Stainless_Fitters")}
                        </h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t("Hydroblasters,_Sandblasters;")}
                        </h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("Spray-Painters;")}</h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">{t("Carpenters")}</h4>
                      </div>
                    </li>
                    <li className="space-y-1">
                      <div className="flex space-x-2">
                        <img src={Bullet} className="h-6 w-6" alt="" />
                        <h4 className="font-medium">
                          {t("Casual_Workers,_Cleaners,_Helpers;")}
                        </h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ContractStaffing;
