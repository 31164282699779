import ContStaff from "../assets/icons/helmet.svg";
import Engineer from "../assets/icons/crane.svg";
import Project from "../assets/icons/settings.svg";
import Procurement from "../assets/icons/procre.svg";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NavDropDown() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div>
      <div className=" group duration-1000">
        <div onMouseLeave={() => setOpen(false)} className="relative z-10">
          <NavLink
            className="text-left duration-1000 flex justify-between item-center w-full"
            to="/services"
            onMouseOver={() => setOpen(true)}
          >
            <p> {t("Our_Services")}</p>
            <svg
              className="w-4 h-4 mx-1.5"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </NavLink>
          <ul
            className={`absolute left-0 w-[40rem] shadow-xl bg-white flex transition duration-1000 ${
              open ? "block" : "hidden"
            }`}
          >
            <Link
              to="/contract-staffing"
              className="inline-flex w-full items-center justify-center p-6 text-sm hover:bg-[#EEEEEE] "
            >
              <img className="w-8 m-1" src={ContStaff} alt="" />

              <p className="m-1 text-xs text-[#1A428A] ">{t("CONTRACT_STAFFING")} </p>
            </Link>

            <Link
              to="/engineering-design"
              className="inline-flex w-full items-center justify-center p-6 text-sm hover:bg-[#EEEEEE]"
            >
              <img className="w-8 m-1" src={Engineer} alt="" />
              <p className="m-1 text-xs text-[#1A428A]"> {t("ENGINEERING_DESIGN")}</p>
            </Link>
            <Link
              to="/project-management"
              className="inline-flex w-full items-center justify-center p-6 text-sm hover:bg-[#EEEEEE]"
            >
              <img className="w-8 m-1" src={Project} alt="" />
              <p className="m-1 text-xs text-[#1A428A]">{t("PROJECT_MANAGEMENT")}</p>
            </Link>
            <Link
              to="/procurement"
              className="inline-flex w-full items-center p-6 justify-center text-sm hover:bg-[#EEEEEE]"
            >
              <img className="w-8 m-1" src={Procurement} alt="" />
              <p className="m-1 text-xs text-[#1A428A]">{t("PROCUREMENT")}</p>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}
